import { BiLogoGmail } from "react-icons/bi";
import { FaLinkedin, FaSquareGithub, FaXTwitter } from "react-icons/fa6";
import "./Contact.scss";

export default function Contact() {
  const mailTo = () => {
    const mail = {
      email: "fikriansyah@mrizki.com",
      subject: "I have opportunity for you!",
      body: "Hi Rizki, Do you have a chance to talk about a new opportunity?",
    };

    return `mailto:${mail.email}?subject=${mail.subject}&body=${mail.body}`;
  };

  const linkedinStyle = {
    color: "#0077B5",
  };
  const githubStyle = {
    color: "#24292E",
  };
  const gmailStyle = {
    color: "#D14836",
  };

  const iconProps = {
    size: 80,
  };

  return (
    <div className="contact">
      <div className="linked-in">
        <a
          href="https://www.linkedin.com/in/rizkifikri/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedin {...iconProps} style={linkedinStyle} />
        </a>
      </div>
      <div className="github gh-user-klovack">
        <a
          href="https://www.github.com/klovack"
          target="_blank"
          rel="noreferrer"
        >
          <FaSquareGithub {...iconProps} style={githubStyle} />
        </a>
      </div>
      <div className="github gh-user-rizki">
        <a href="https://x.com/rizkifikri" target="_blank" rel="noreferrer">
          <FaXTwitter {...iconProps} style={githubStyle} />
        </a>
      </div>
      <div className="email">
        <a href={mailTo()} target="_blank" rel="noreferrer">
          <BiLogoGmail {...iconProps} style={gmailStyle} />
        </a>
      </div>
    </div>
  );
}
