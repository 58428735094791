import { Html, useBounds } from "@react-three/drei";
import { PrimitiveProps, useFrame } from "@react-three/fiber";
import React, { Suspense, useEffect, useMemo, useRef } from "react";
import { Group } from "three";
import "./PhoneScreen.scss";
import Iphone from "../Models/Phone/Iphone";
import pointerEvents from "../utils/pointerEvents";
import Contact from "./Contact/Contact";
import RealtimeDate from "./RealtimeDate/RealtimeDate";
import useOutsideClickListener from "../hooks/useOutsideClickListener";
import { debounce } from "lodash";
import NotificationVolume from "./NotificationVolume/NotificationVolume";
import PhoneOffButton from "./PhoneOffButton/PhoneOffButton";
import PhoneNotification from "./PhoneNotification/PhoneNotification";

const phoneVibrate = new Audio("sfx/vibrate.m4a");
phoneVibrate.volume = 0.5;
const DURATION = {
  PHONE_VIBRATE_INTERVAL: 60 * 1000,
  SCREEN_ON: 180 * 1000,
  SCREEN_OFF: 5 * 1000,
};

const timerId: NodeJS.Timeout[] = [];

export default function PhoneScreen(props: Omit<PrimitiveProps, "object">) {
  const [phoneNotificationIds, setPhoneNotificationId] = React.useState<
    NodeJS.Timeout[]
  >([]);
  const [isVibrating, setIsVibrating] = React.useState(false);
  const [isScreenOn, setIsScreenOn] = React.useState(false);
  const phoneRef = useRef<Group | null>(null);
  const htmlRef = useRef<HTMLDivElement | null>(null);
  const boundsApi = useBounds();

  const screenOffDebounce = useMemo(
    () =>
      debounce(
        () => {
          setIsScreenOn(false);
        },
        DURATION.SCREEN_OFF,
        {
          trailing: true,
          maxWait: DURATION.SCREEN_OFF,
        }
      ),
    []
  );

  useOutsideClickListener(htmlRef, () => {
    screenOffDebounce();
  });

  const stopVibrating = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    phoneVibrate.pause();
    setIsVibrating(false);
    phoneNotificationIds.forEach((id) => clearInterval(id));
    timerId.forEach((id) => clearInterval(id));
    setPhoneNotificationId([]);
    screenOffDebounce.cancel();
  };

  const enableVibrating = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    const id = setInterval(() => {
      setIsVibrating(true);
      phoneVibrate.play();
      phoneVibrate.onended = () => {
        setIsVibrating(false);
      };
    }, DURATION.PHONE_VIBRATE_INTERVAL);
    timerId.push(id);
    setPhoneNotificationId([...phoneNotificationIds, id]);
    screenOffDebounce.cancel();
    return id;
  };

  useFrame(({ clock }) => {
    if (phoneRef.current && isVibrating && !!phoneNotificationIds) {
      phoneRef.current.position.x +=
        Math.sin(clock.getElapsedTime() * 1000) * 0.003;
      phoneRef.current.position.z +=
        Math.sin(clock.getElapsedTime() * 1000) * 0.003;
      phoneRef.current.rotation.y +=
        Math.sin(clock.getElapsedTime() * 1000) * 0.002;
    }
  });

  // Add notification every 30 seconds
  // if user hasn't interacted with the phone
  useEffect(() => {
    enableVibrating();
    return () => {
      timerId.forEach((id) => clearInterval(id));
      timerId.splice(0, timerId.length);
      phoneNotificationIds.forEach((id) => clearInterval(id));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isScreenOn) {
      if (phoneRef.current) {
        boundsApi.refresh(phoneRef.current).clip().fit();
      }
    } else {
      boundsApi.refresh().fit();
    }
  }, [isScreenOn, boundsApi]);

  return (
    <>
      <group
        position={[2, -1, 0.95]}
        rotation={[-Math.PI / 2, 0, -Math.PI / 12]}
        scale={0.45}
        ref={phoneRef}
        {...props}
        {...pointerEvents}
        onClick={(e) => {
          e.stopPropagation();
          setIsScreenOn(true);
          setTimeout(() => {
            setIsScreenOn(false);
          }, DURATION.SCREEN_ON);
        }}
      >
        <Iphone castShadow receiveShadow />

        {isScreenOn && (
          <Suspense>
            <Html
              wrapperClass="iphone-wrapper"
              occlude={"blending"}
              transform
              zIndexRange={[100, 0]}
              position={[0.165, 1.33, 0.082]}
              rotation={[0, 0, 0]}
              distanceFactor={3.3}
              scale={0.5}
              ref={htmlRef}
            >
              <div className="iPhone" onClick={stopVibrating}>
                <PhoneNotification
                  isShowing={phoneNotificationIds.length > 0}
                />
                <PhoneOffButton
                  className="phone-off"
                  onPowerOff={() => setIsScreenOn(false)}
                />
                <NotificationVolume
                  phoneNotificationIds={phoneNotificationIds}
                  onVolumeOff={enableVibrating}
                  onVolumeHigh={stopVibrating}
                />
                <RealtimeDate />

                <Contact />
              </div>
            </Html>
          </Suspense>
        )}
      </group>
    </>
  );
}
