import React from "react";
import { getLang } from "../../utils/getLang";
import "./RealtimeDate.scss";

export default function RealtimeDate() {
  const [time, setTime] = React.useState(new Date());

  React.useEffect(() => {
    // update date every 15 seconds
    // because we never know when the user will open the page
    // and we want to make sure the date is always up to date
    const timer = setInterval(() => {
      setTime(new Date());
    }, 15 * 1000);
    return () => {
      clearInterval(timer);
    };
  });
  const timeDisplay = Intl.DateTimeFormat(getLang(), {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  }).format(time);
  const dateDisplay = Intl.DateTimeFormat(getLang(), {
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(time);

  return (
    <div className="datetime">
      <p className="time">{timeDisplay}</p>
      <p className="date">{dateDisplay}</p>
    </div>
  );
}
