import { IoIosVolumeOff, IoIosVolumeHigh } from "react-icons/io";
import "./NotificationVolume.scss";

type NotificationVolumeProps = {
  phoneNotificationIds: NodeJS.Timeout[];
  onVolumeOff: () => void;
  onVolumeHigh: () => void;
};

const NotificationVolume = ({
  phoneNotificationIds,
  onVolumeOff: enableVibrating,
  onVolumeHigh: stopVibrating,
}: NotificationVolumeProps) => {
  return (
    <div
      className={
        "volume-adjustment" +
        (phoneNotificationIds.length > 0 ? " vibrate" : "")
      }
    >
      {phoneNotificationIds.length === 0 ? (
        <IoIosVolumeOff
          onClick={enableVibrating}
          size={100}
          className="volume-off"
        />
      ) : (
        <IoIosVolumeHigh
          onClick={stopVibrating}
          size={100}
          className="volume-on"
        />
      )}
    </div>
  );
};

export default NotificationVolume;
