import { useState } from "react";
import "./PhoneNotification.scss";
import { MdOutlineNotificationsActive } from "react-icons/md";

type PhoneNotificationProps = {
  isShowing?: boolean;
};

const PhoneNotification = ({ isShowing = false }: PhoneNotificationProps) => {
  const [isOn, setIsOn] = useState(isShowing);
  const notificationClass = `phone-notification ${!isOn ? " hidden" : ""}`;

  return (
    <div className={notificationClass} onClick={() => setIsOn(false)}>
      <div className="phone-notification__icon">
        <MdOutlineNotificationsActive size={60} />
      </div>
      <div className="phone-notification__content">
        <h1 className="phone-notification__content__title">Remember!</h1>
        <p className="phone-notification__content__message">
          Don't forget to check my social media!
        </p>
      </div>
    </div>
  );
};

export default PhoneNotification;
