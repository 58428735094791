import { debounce } from "lodash";
import { DetailedHTMLProps, useMemo, useState } from "react";
import { BiPowerOff } from "react-icons/bi";
import "./PhoneOffButton.scss";
import { isMobile } from "react-device-detect";

type PhoneOffButtonProps = Omit<
  DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  "onMouseDown" | "onMouseUp"
> & {
  onPowerOff: () => void;
};

const PhoneOffButton = ({
  onPowerOff,
  className,
  ...props
}: PhoneOffButtonProps) => {
  const [isPoweringOff, setIsPoweringOff] = useState(false);
  const handlePowerOff = useMemo(
    () =>
      debounce(
        () => {
          onPowerOff();
        },
        isMobile ? 100 : 1000
      ),
    [onPowerOff]
  );

  const buttonClass = `phone-off-button ${className}`;

  return (
    <button
      {...props}
      className={buttonClass + (isPoweringOff ? " powering-off" : "")}
      onPointerDown={(e) => {
        e.stopPropagation();
        handlePowerOff();
        setIsPoweringOff(true);
      }}
      onPointerUp={(e) => {
        e.stopPropagation();
        handlePowerOff.cancel();
        setIsPoweringOff(false);
      }}
      onPointerLeave={(e) => {
        e.stopPropagation();
        handlePowerOff.cancel();
        setIsPoweringOff(false);
      }}
    >
      <BiPowerOff size={40} />
    </button>
  );
};

export default PhoneOffButton;
